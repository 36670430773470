<template>
  <div class="Investment_cont">
    <el-card>
      <div class="search">
      <el-input v-model="info" placeholder="输入名字进行搜索" clearable @input="get_add_company_search" @clear="get_add_company_search"></el-input>
      <el-button type="primary" @click="get_add_company_search">查询</el-button>
    </div>
    </el-card>
    <!-- 园区企业 -->
    <el-card class="Industry enterpriseList">
      <div class="Industry_title Industry_title_btn">
        <el-button type="primary" @click="addCompanyToBtn">新增企业</el-button>
      </div>
      <el-table :data="selectData.length != 0 ? selectData : tableData" border style="width: 80%" class="Industry_table">
        <el-table-column type="index" label="序号" width="50" align="center">
          <template slot-scope="scope">
            <div v-text="(queryInfo.page - 1) * 10 + scope.$index + 1"></div>
          </template>
        </el-table-column>
        <el-table-column prop="park" label="所属园区" align="center"></el-table-column>
        <el-table-column prop="company_name" label="企业名称" align="center"></el-table-column>
        <el-table-column prop="company_info" width="400px" label="企业简介" align="center">
          <template slot-scope="scope">
            <span class="infoHeight">{{scope.row.company_info ? scope.row.company_info : '-'}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="order_num" label="排序" align="center"></el-table-column>
        <el-table-column prop="created_at" label="创建时间" align="center">
          <template slot-scope="scope">
            <span>{{timestampToTime(scope.row.created_at)}}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <!-- 修改按钮 -->
            <el-button type="text" @click="showEditDialog(scope.row.id)">编辑</el-button>
            <!-- 删除按钮 -->
            <el-button type="text"  @click="removeInfo(scope.row.id)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页区域 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.page"
        :total="queryInfo.all_count">
      </el-pagination>
    </el-card>
    <!-- 新增园区企业的对话框 -->
    <el-dialog title="新增企业" :center="true" :closeOnClickModal="false" class="dialogIndustry" :visible.sync="dialogVisible" width="50%" >
        <!-- 内容主体区 -->
        <el-form ref="addCompanyRef" :model="add_company" :rules="companyRules" label-width="100px">
          <el-form-item label="所属园区：" prop="park_id">
            <el-select v-model="add_company.park_id" placeholder="请选择园区">
              <el-option v-for="(item,index) in listSelect" :key="index" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="企业名称：" prop="company_name">
            <el-input v-model="add_company.company_name" placeholder="请输入企业名称"></el-input>
          </el-form-item>
          <el-form-item label="企业介绍">
            <el-input type="textarea" :rows="8" placeholder="请输入企业介绍" v-model="add_company.company_info"></el-input>
          </el-form-item>
          <el-form-item label="排序：" prop="order_num">
            <el-input type="age" v-model.number="add_company.order_num" placeholder="请输入排序值，值越小排越前"></el-input>
          </el-form-item>
        </el-form>
        <!-- 底部区域 -->
        <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="addCompanyTo">确 定</el-button>
        </span>
    </el-dialog>
    <!-- 编辑园区企业的对话框 -->
    <el-dialog title="编辑企业" :center="true" class="dialogIndustry" :visible.sync="dialogVisibleEdit" width="50%" >
        <!-- 内容主体区 -->
        <el-form ref="addCompanyRef" :model="get_company_data" :rules="companyRules" label-width="100px">
          <el-form-item label="所属园区：" prop="park_id">
            <el-select v-model="get_company_data.park_id" placeholder="请选择园区">
              <el-option v-for="(item,index) in listSelect" :key="index" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="企业名称：" prop="company_name">
            <el-input v-model="get_company_data.company_name" placeholder="请输入企业名称"></el-input>
          </el-form-item>
          <el-form-item label="企业简介">
            <el-input type="textarea" :rows="8" placeholder="请输入企业简介" v-model="get_company_data.company_info"></el-input>
          </el-form-item>
          <el-form-item label="排序：" prop="order_num">
            <el-input type="age" v-model.number="get_company_data.order_num" placeholder="请输入排序值，值越小排越前"></el-input>
          </el-form-item>
        </el-form>
        <!-- 底部区域 -->
        <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisibleEdit = false">取 消</el-button>
            <el-button type="primary" @click="EditCompanyTo">确 定</el-button>
        </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data () {
    return {
      info: '',
      // 表格的数据
      tableData: [],
      // 添加按钮的显示
      dialogVisible: false,
      // 编辑按钮的显示
      dialogVisibleEdit: false,
      // 添加企业表单的数据
      add_company: {
        park_id: '',
        company_name: '',
        company_info: '',
        order_num: ''
      },
      // 获取当前企业表单的数据
      get_company_data: {
        park_id: '',
        company_name: '',
        company_info: '',
        order_num: ''
      },
      // 对话框的规则
      companyRules: {
        park_id: [
          { required: true, message: '不能为空', trigger: 'blur' }
        ],
        company_name: [
          { required: true, message: '不能为空', trigger: 'blur' }
        ],
        order_num: [
          { required: true, type: 'number', message: '必须为数字值', trigger: 'blur' }
        ]
      },
      // 园区企业的分页
      queryInfo: {
        // 总条目数
        all_count: 2,
        // 页码
        page: 1,
        // 总页数
        all_page: 1
      },
      // 企业列表新增园区选项
      listSelect: [],
      // 查询到的数据
      selectData: []
    }
  },
  async created () {
    this.get_add_company()
  },
  methods: {
    getQueryData () {
      if (!this.info) this.queryData()
    },
    timestampToTime (timestamp) {
      // 计算年月日时分的函数
      var date = new Date(timestamp)
      var Y = date.getFullYear() + '-'
      var M = (date.getMonth() + 1) + '-'
      var D = date.getDate() + ' '
      var h = date.getHours() + ':'
      var m = date.getMinutes() + ':'
      var s = date.getSeconds()
      return Y + M + D + h + m + s
    },
    // 查询数据
    async queryData () {
      const { data: res } = await this.$http.get(`search_company?search=${this.info}&limit=10&page=1`)
      this.selectData = res.data
      this.queryInfo = res.pagination
    },
    get_add_company_search () {
      this.queryInfo.page = 1
      this.get_add_company()
    },
    // 获取园区列表的数据
    async get_add_company () {
      let searchQuery = ''
      if (this.info !== '') {
        searchQuery = '&search=' + this.info
      }
      const { data: res } = await this.$http.get(`company_list?limit=10&page=${this.queryInfo.page}` + searchQuery)
      this.tableData = res.data
      this.queryInfo.all_count = res.pagination.all_count
      this.queryInfo.page = res.pagination.page
      this.queryInfo.all_page = res.pagination.all_page
    },
    // 新增园区列表的数据按钮
    async addCompanyToBtn () {
      const { data: res } = await this.$http.get('park_list')
      this.listSelect = res
      this.dialogVisible = true
      this.add_company.park_id = ''
      this.add_company.company_name = ''
      this.add_company.company_info = ''
      this.add_company.order_num = ''
    },
    // 新增园区列表的数据
    addCompanyTo () {
      this.$refs.addCompanyRef.validate(async valid => {
        if (!valid) return false
        const { data: res } = await this.$http.post('company_list',
          {
            park_id: this.add_company.park_id,
            company_name: this.add_company.company_name,
            company_info: this.add_company.company_info,
            order_num: this.add_company.order_num
          })
        this.get_add_company()
        this.dialogVisible = false
        if (res.msg === 'success') this.$message({ message: '添加成功', type: 'success' })
      })
    },
    // 点击编辑按钮
    async showEditDialog (id) {
      this.dialogVisibleEdit = true
      const { data: res } = await this.$http.get(`company_list/${id}`)
      const { data: res2 } = await this.$http.get('park_list')
      this.listSelect = res2
      this.get_company_data = res.data
    },
    // 确认编辑
    async EditCompanyTo () {
      const { data: res } = await this.$http.post(`company_list/${this.get_company_data.id}`, {
        park_id: this.get_company_data.park_id,
        company_name: this.get_company_data.company_name,
        company_info: this.get_company_data.company_info,
        order_num: this.get_company_data.order_num,
        _method: 'put'
      })
      this.get_add_company()
      if (res.msg === 'success') this.$message({ message: '编辑成功', type: 'success' })
      this.dialogVisibleEdit = false
    },
    // 删除园区企业的数据
    async removeInfo (id) {
      const { data: res } = await this.$http.delete(`company_list/${id}`)
      this.get_add_company()
      if (res.msg === 'success') this.$message({ message: '删除成功', type: 'success' })
    },
    // 监听 园区企业 pagesize改变的事件
    handleSizeChange (newSize) {
      this.queryInfo.all_count = newSize
    },
    // 监听 园区企业 页码值 改变的事件
    handleCurrentChange (newPage) {
      this.queryInfo.page = newPage
      this.get_add_company()
    }
  }
}
</script>

<style lang="less" scoped>
.Investment_cont{
  padding: 1rem;
  .lease{
    margin-bottom: 1rem;
    .lease_title{
      position: relative;
      h2{
        padding-left: 0.7rem;
        font-size: 1.25rem;
        font-weight: bold;
      }
      ::after{
        content: '';
        position: absolute;
        left: 0;
        top: 4px;
        width: 3px;
        height: 20px;
        background-color: #000;
      }
    }
    .leaseFrom{
      width: 50%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding: 1rem;
      h3{
        width: 100%;
        font-size: 1rem;
        margin-left: -0.3125rem;
        padding: 0.5rem 0;
      }
      .el-form-item{
        width: 33%;
        text-align: center;
        margin-bottom: 0;
        .el-input{
          width: 80%;
          margin-left: -1rem;
        }
      }
    }
  }
  .detailed{
    margin-bottom: 1rem;
    .detailed_title{
      position: relative;
      h2{
        padding-left: 0.7rem;
        font-size: 1.25rem;
        font-weight: bold;
      }
      ::after{
        content: '';
        position: absolute;
        left: 0;
        top: 4px;
        width: 3px;
        height: 20px;
        background-color: #000;
      }
    }
    .detailed_box{
      width: 50%;
      display: flex;
      flex-direction: column;
      .detailed_box_title{
        display: flex;
        justify-content: space-between;
        padding: 1rem 0;
        span{
          width: 20%;
          text-align: center;
        }
      }
      .detailed_items{
        display: flex;
        justify-content: space-between;
        // margin-bottom: 1rem;
        text-align: center;
        .item{
          width: 20%;
          display: flex;
          justify-content: center;
          .el-input{
            width: 80%;
            .el-input--small .el-input__inner{
              text-align: center;
            }
          }
        }
      }
    }
  }
  .Industry{
    .Industry_title_btn{
      display: flex;
      align-items: center;
      .el-button{
        width: 7%;
        padding: 8px;
        font-size: 14px;
        border-radius: 5px;
        align-self: flex-start;
        // margin-left: 2rem;
      }
    }
    .Industry_table{
      margin: 1rem 0;
    }
  }
  .dialogIndustry{
    .el-form{
      width: 80%;
      .el-select{
        width: 100%;
      }
    }
  }
}
.Investment_cont{
  padding: 1rem;
  .el-card{
    &:nth-child(1){
      margin-bottom: 1rem;
    }
  }
  h2{
    font-size: 1.25rem;
    font-weight: 500;
  }
  .search{
    width: 50%;
    display: flex;
    align-items: center;
    padding: 1rem 0;
    .el-input{
      width: 45%;
    }
    .el-button{
      margin-left: 2rem;
      background-color: #275fdb;
      border-color: #275fdb;
      border-radius: 7px;
      padding: 10px 30px;
    }
  }
}
.infoHeight{
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
</style>
